'use strict';

window.addEventListener('load', function () {
	load();


});


//load content 
function load(){
	document.body.classList.add('show');
	var preloader = document.querySelector('#preloader');

	preloader.classList.add('hide');
	setTimeout(function(){
		preloader.remove();
	}, 2000);
}


//parallax
$(window).scroll(function() {
	var top = $(this).scrollTop();
	var sec_1 = $('.hero').height();
	//console.log(top - sec_1);
	if ($(window).width() > 1100) {
		$('.desktop .about__content').css({
			'transform': 'translate(0,'+ (top - sec_1)/8 +'px)'
		});
		$('.desktop .about__img').css({
			'top': -(top - sec_1)/4
		});
	}
});
